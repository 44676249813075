const modernizr = require("modernizr");

const unsupportedBrowserUrl = '/unsupported-browser';

const browserTests = [
  "flexbox",
  "promises",
  "classlist",
  "dataset",
  "srcset",
  "svg",
  "inlinesvg",
  "svgasimg",
  "positionsticky",
  "displaycontents"
];

modernizr.addTest('displaycontents', function() {
  return modernizr.testAllProps('display', 'contents');
});

modernizr.addTest('positionsticky', function() {
  return modernizr.testAllProps('position', 'sticky');
});

let failedTests = [];

browserTests.forEach(function(browserTest) {
  if (!modernizr[browserTest]) {
    failedTests.push(browserTest);
  }
});

if (failedTests.length != 0 && window.location.href.indexOf(unsupportedBrowserUrl) == -1) {
  window.location = unsupportedBrowserUrl + '?failed=' + failedTests.join();
}
